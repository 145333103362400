import * as c from "../constants";
import API from '../api';

export const toggleDrawer = (value) => ({
    type: c.TOGGLE_DRAWER,
    payload: value
})

export const toggleLoading = (value) => ({
    type: c.TOGGLE_LOADING_FORM,
    payload: value
})

// DEVICES 
export const getDevices = () => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get('/getDevices' ,config)
        dispatch({ type: c.GET_DEVICES, payload: response.data})

        return response
    } catch (error) {
        throw error
    }
}

export const storeDevice = (data) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.post('/storeDevice', data ,config)
        return response
    } catch (error) {
        throw error
    }
}

export const updateDevice = (data, id) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.post(`/updateDevice/${id}`, data ,config)
        return response
    } catch (error) {
        throw error
    }
}

// RESERVATIONS
export const getReservations = (filters) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get(`/getReservations?status=${filters.status}${filters.device_id !== 'all' ? `&device_id=${filters.device_id}` : ``}${filters.contact_person !== '' ? `&contact_person=${filters.contact_person}` : ``}`, config)
        dispatch({ type: c.GET_RESERVATIONS, payload: response.data})

        return response
    } catch (error) {
        throw error
    }
}

export const storeReservation = (data) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.post('/storeReservation',data ,config)
        return response
    } catch (error) {
        throw error
    }
}

export const updateReservation = (data, id) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.post(`/updateReservation/${id}`,data ,config)
        return response
    } catch (error) {
        throw error
    }
}

export const cancelReservation = (id) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get(`/cancelReservation/${id}`, config)
        return response
    } catch (error) {
        throw error
    }
}

export const activateReservation = (id) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get(`/activateReservation/${id}`,config)
        return response
    } catch (error) {
        throw error
    }
}

export const addReservationPrice = (data, id) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.post(`/addReservationPrice/${id}`, data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const addExpenseToReservation = (data) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.post('/addExpenseToReservation', data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const getExpensesOfReservation = (id) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get(`/getExpensesOfReservation/${id}`, config)

        return response
    } catch (error) {
        throw error
    }
}

export const getPaymentHistoryOfReservation = (id) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get(`/getPaymentHistoryOfReservation/${id}`, config)

        return response
    } catch (error) {
        throw error
    }
}

// PAYMENTS
export const getPayments = (filters) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get(`/getPayments${filters.device_id !== 'all' ? `?device_id=${filters.device_id}` : ``}`, config)
        dispatch({ type: c.GET_PAYMENTS, payload: response.data})

        return response
    } catch (error) {
        throw error
    }
}

export const deletePayment = (id) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get(`/deletePayment/${id}`,config)
        return response
    } catch (error) {
        throw error
    }
}

// EXPENSES
export const getExpenses = (filters) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get(`/getExpenses${filters.device_id !== 'all' ? `?device_id=${filters.device_id}` : ``}`, config)
        dispatch({ type: c.GET_EXPENSES, payload: response.data})

        return response
    } catch (error) {
        throw error
    }
}

export const deleteExpense = (id) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get(`/deleteExpense/${id}`,config)
        return response
    } catch (error) {
        throw error
    }
}