import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, TextField, LinearProgress, Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@mui/material';
import { toggleLoading, toggleDrawer, updateReservation } from '../../../redux/actions/app';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';

const EditReservation = (props) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const validationSchema = yup.object({
        device_id: yup.string().required('Ju lutem selektoni pajisjen'),
        contact_person: yup.string().required('Ju lutem shkruani emrin e klientit'),
        contact_person_mobile: yup.string().required('Ju lutem shkruani numrin kontaktues'),
        address: yup.string().required('Ju lutem shkruani adresen'),
        event: yup.string(),
        info: yup.string(),
        date: yup.string().required('Ju lutem selektoni daten'),
        time_from: yup.string(),
        duration: yup.string(),
        price: yup.string().required('Ju lutem shkruani cmimin')
    });

    const formik = useFormik({
        initialValues: {
          device_id: props.reservation.device_id,
          contact_person: props.reservation.contact_person,
          contact_person_mobile: props.reservation.contact_person_mobile,
          address: props.reservation.address,
          event: props.reservation.event,
          info: props.reservation.info,
          date: props.reservation.date,
          time_from: props.reservation.time_from,
          duration: props.reservation.duration,
          price: props.reservation.price
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true);
            dispatch(toggleLoading(true));
            dispatch(updateReservation(values, props.reservation.id)).then(res => {
                setLoading(false);
                dispatch(toggleLoading(false));
                dispatch(toggleDrawer(false));
                toast.success('Pajisja u shtua me sukses!');
                props.onFinish();
            }).catch(e => {
                setLoading(false);
                dispatch(toggleLoading(false));
                toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
            });
        },
    });

    return (
        <div>
            <form onSubmit={formik.handleSubmit}> 
                <div className='mt-3'>
                    <FormControl fullWidth size='small' error={formik.errors.device_id}>
                        <InputLabel id="demo-simple-select-label">Selekto pajisjen</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formik.values.device_id}
                            label="Selekto pajisjen"
                            name="device_id"
                            onChange={formik.handleChange}
                        >
                            {props.devices.length > 0 && props.devices.map((device) => (
                                <MenuItem key={device.id} value={device.id}>{device.name}</MenuItem>
                            ))}
                        </Select>
                        {formik.errors.device_id && (
                            <FormHelperText style={{ color: 'red' }}>{formik.errors.device_id}</FormHelperText>
                        )}
                    </FormControl>
                </div> 
                <div className='mt-3'>
                    <TextField
                        autoFocus
                        name="contact_person"
                        variant="outlined"
                        fullWidth
                        label="Emri i klientit"
                        size="small"
                        value={formik.values.contact_person}
                        onChange={formik.handleChange}
                        error={formik.touched.contact_person && Boolean(formik.errors.contact_person)}
                        helperText={formik.touched.contact_person && formik.errors.contact_person}
                    />
                </div>
                <div className='mt-3'>
                    <TextField
                        name="contact_person_mobile"
                        variant="outlined"
                        fullWidth
                        label="Numri kontaktues"
                        size="small"
                        value={formik.values.contact_person_mobile}
                        onChange={formik.handleChange}
                        error={formik.touched.contact_person_mobile && Boolean(formik.errors.contact_person_mobile)}
                        helperText={formik.touched.contact_person_mobile && formik.errors.contact_person_mobile}
                    />
                </div>  
                <div className='mt-3'>
                    <TextField
                        name="address"
                        variant="outlined"
                        fullWidth
                        label="Adresa"
                        size="small"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        error={formik.touched.address && Boolean(formik.errors.address)}
                        helperText={formik.touched.address && formik.errors.address}
                    />
                </div>  
                <div className='mt-3'>
                    <TextField
                        name="event"
                        variant="outlined"
                        fullWidth
                        label="Eventi"
                        size="small"
                        value={formik.values.event}
                        onChange={formik.handleChange}
                        error={formik.touched.event && Boolean(formik.errors.event)}
                        helperText={formik.touched.event && formik.errors.event}
                    />
                </div>
                <div className='mt-3'>
                    <TextField
                        name="info"
                        variant="outlined"
                        fullWidth
                        label="Info"
                        size="small"
                        value={formik.values.info}
                        onChange={formik.handleChange}
                        error={formik.touched.info && Boolean(formik.errors.info)}
                        helperText={formik.touched.info && formik.errors.info}
                    />
                </div>  
                <div className='mt-3'>
                    <TextField
                        name="date"
                        type="date"
                        variant="outlined"
                        fullWidth
                        label="Data e rezervimit"
                        size="small"
                        value={formik.values.date}
                        onChange={formik.handleChange}
                        error={formik.touched.date && Boolean(formik.errors.date)}
                        helperText={formik.touched.date && formik.errors.date}
                    />
                </div> 
                <div className='mt-3'>
                    <TextField
                        name="time_from"
                        variant="outlined"
                        fullWidth
                        label="Ora"
                        size="small"
                        value={formik.values.time_from}
                        onChange={formik.handleChange}
                        error={formik.touched.time_from && Boolean(formik.errors.time_from)}
                        helperText={formik.touched.time_from && formik.errors.time_from}
                    />
                </div>  
                <div className='mt-3'>
                    <TextField
                        name="duration"
                        variant="outlined"
                        fullWidth
                        label="Kohezgjatja"
                        size="small"
                        value={formik.values.duration}
                        onChange={formik.handleChange}
                        error={formik.touched.duration && Boolean(formik.errors.duration)}
                        helperText={formik.touched.duration && formik.errors.duration}
                    />
                </div>   
                <div className='mt-3'>
                    <TextField
                        name="price"
                        variant="outlined"
                        fullWidth
                        label="Marreveshja per cmim"
                        size="small"
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        error={formik.touched.price && Boolean(formik.errors.price)}
                        helperText={formik.touched.price && formik.errors.price}
                    />
                </div>   
                <div className="text-center mt-3">
                    <Button variant="contained" type="submit">Ruaj rezervimin</Button>
                </div>
                {loading && (
                    <div className='mt-3'>
                        <LinearProgress />
                    </div>
                )}
            </form>
        </div>
    );
};

export default EditReservation;