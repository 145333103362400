import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, TextField, LinearProgress, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { toggleLoading, toggleDrawer, addExpenseToReservation, getExpensesOfReservation, deleteExpense } from '../../../redux/actions/app';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';

const AddExpense = (props) => {
    const dispatch = useDispatch();
    const { reservation } = props;

    const [loading, setLoading] = useState(false);
    const [history, setHistory] = useState([]);

    const validationSchema = yup.object({
        price: yup.string("").required('Ju lutem shkruani shumën për pagesë'),
        reason: yup.string().required("Ju lutem shkruani arsyen e ketij shpenzimi"),
        info: yup.string()
    });

    const formik = useFormik({
        initialValues: {
          price: '',
          reason: "",
          info: "",
          device_id: reservation.device.id,
          reservation_id: reservation.id
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true);
            dispatch(toggleLoading(true));
            dispatch(addExpenseToReservation(values)).then(res => {
                setLoading(false);
                dispatch(toggleLoading(false));
                dispatch(toggleDrawer(false));
                toast.success('Shpenzimi u ruajt me sukses!');
                props.onFinish();
            }).catch(e => {
                setLoading(false);
                dispatch(toggleLoading(false));
                toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
            });
        },
    });

    const onDeleteExpense = (id) => {
        dispatch(toggleLoading(true));
        dispatch(deleteExpense(id)).then(res => {
            props.onFinish();
            dispatch(toggleLoading(false));
            dispatch(toggleDrawer(false));
            toast.success('Shpenzimi u fshi me sukses!');
        }).cathc(err => {
            setLoading(false);
            dispatch(toggleLoading(false));
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        });
    }

    useEffect(() => {
        dispatch(getExpensesOfReservation(reservation.id)).then(res => {
            setHistory(res.data);
        });
    }, [dispatch, reservation]);

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className='mt-3'>
                    <TextField
                        autoFocus
                        name="price"
                        variant="outlined"
                        fullWidth
                        label="Pagesa"
                        type="number"
                        size="small"
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        error={formik.touched.price && Boolean(formik.errors.price)}
                        helperText={formik.touched.price && formik.errors.price}
                    />
                </div> 
                <div className='mt-3'>
                    <TextField
                        name="reason"
                        variant="outlined"
                        fullWidth
                        label="Arsyeja e shpenzimit"
                        size="small"
                        value={formik.values.reason}
                        onChange={formik.handleChange}
                        error={formik.touched.reason && Boolean(formik.errors.reason)}
                        helperText={formik.touched.reason && formik.errors.reason}
                    />
                </div>  
                <div className='mt-3'>
                    <TextField
                        name="info"
                        variant="outlined"
                        fullWidth
                        label="Info"
                        size="small"
                        value={formik.values.info}
                        onChange={formik.handleChange}
                        error={formik.touched.info && Boolean(formik.errors.info)}
                        helperText={formik.touched.info && formik.errors.info}
                    />
                </div>  
                <div className="text-center mt-3">
                    <Button variant="contained" type="submit">Ruaj</Button>
                </div>
                {loading && (
                    <div className='mt-3'>
                        <LinearProgress />
                    </div>
                )}
            </form>
            {history.length > 0 && (
                <div className='mt-5'>
                    <hr></hr>
                    <p style={{ fontSize: '14px', textAlign: 'center' }}><b>Historia e shpenzimeve</b></p>
                    {history.map(his => (
                        <div className='history-card' key={his.id} style={{ position: 'relative' }}>
                            <IconButton style={{ position: 'absolute', right: '5px' }} size='small' onClick={() => onDeleteExpense(his.id)}>
                                <DeleteIcon fontSize='small' />
                            </IconButton>
                            <p>Cmimi: <span>{his.price} €</span></p>
                            <p>Arsye: <span>{his.reason}</span></p>
                            {his.info && (<p>Info: <span>{his.info}</span></p>)}
                            <p>Data: <span>{his.created_at}</span></p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default AddExpense;