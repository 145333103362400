import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, TextField, LinearProgress } from '@mui/material';
import { toggleLoading, toggleDrawer, storeDevice } from '../../../redux/actions/app';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';

const AddDevice = (props) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const validationSchema = yup.object({    
        name: yup.string('Shkruaj nje emer identifikues per pajisjen').required('Emri identifikues eshte i domosdoshem'),
        description: yup.string('Shkruaj pershkrimin'),
        color: yup.string("Selekto ngjyren")
    });

    const formik = useFormik({
        initialValues: {
          name: "",
          description: "",
          color: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true);
            dispatch(toggleLoading(true));
            dispatch(storeDevice(values)).then(res => {
                setLoading(false);
                dispatch(toggleLoading(false));
                dispatch(toggleDrawer(false));
                toast.success('Pajisja u shtua me sukses!');
                props.onFinish();
            }).catch(e => {
                setLoading(false);
                dispatch(toggleLoading(false));
                toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
            });
        },
    });

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className='mt-3'>
                    <TextField
                        autoFocus
                        name="name"
                        variant="outlined"
                        fullWidth
                        label="Emri identifikues i pajisjes"
                        size="small"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                </div>
                <div className='mt-3'>
                    <TextField
                        name="description"
                        variant="outlined"
                        fullWidth
                        label="Pershkrimi"
                        size="small"
                        rows="3"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        helperText={formik.touched.description && formik.errors.description}
                    />
                </div>  
                <div className='mt-3'>
                    <TextField
                        name="color"
                        variant="outlined"
                        fullWidth
                        label="Ngjyra qe shfaqet ne kalendar"
                        size="small"
                        type="color"
                        value={formik.values.color}
                        onChange={formik.handleChange}
                        error={formik.touched.color && Boolean(formik.errors.color)}
                        helperText={formik.touched.color && formik.errors.color}
                    />
                </div>    
                <div className="text-center mt-3">
                    <Button variant="contained" type="submit">Ruaj pajisjen</Button>
                </div>
                {loading && (
                    <div className='mt-3'>
                        <LinearProgress />
                    </div>
                )}
            </form>
        </div>
    );
};

export default AddDevice;