import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Table, TableHead, TableRow, TableCell, TableBody, LinearProgress, IconButton } from '@mui/material';
import MainPage from '../../components/main-page/MainPage';
import { toggleDrawer, getDevices } from '../../redux/actions/app';
import RightDrawer from '../../components/right-drawer/RightDrawer';
import AddDevice from './components/AddDevice';
import UpdateDeviceComponent from './components/UpdateDeviceComponent';
import toast from 'react-hot-toast';
import EditIcon from '@mui/icons-material/Edit';

const Devices = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const devices = useSelector((state) => state.app.devices);
    const [form, setForm] = useState();
    const [selectedDevice, setSelectedDevice] = useState();
    
    const onAddNewDevice = () => {
        setForm("add");
        dispatch(toggleDrawer(true));
    }

    const onEditDevice = (device) => {
        setSelectedDevice(device);
        setForm("edit");
        dispatch(toggleDrawer(true));
    }

    const fetchData = useCallback(() => {
        dispatch(getDevices()).then(res => {
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        });
    }, [dispatch]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <>
            <MainPage title="Lista e pajisjeve" buttonTitle="Pajisje e re" onButtonClick={onAddNewDevice} />

            <div className='mt-2' style={{ overflow: 'auto' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Emri</b></TableCell>
                            <TableCell><b>Pershkrimi</b></TableCell>
                            <TableCell><b>Ngjyra</b></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading && devices && devices.map((device) => (
                            <TableRow key={device.id} >
                                <TableCell>{device.name}</TableCell>
                                <TableCell>{device.description}</TableCell>
                                <TableCell><div style={{ width: '10px', height: '10px', borderRadius: '5px', backgroundColor: device.color }}></div></TableCell>
                                {/* <TableCell>
                                    <IconButton onClick={() => onEditDevice(device)}>
                                        <EditIcon fontSize='small' />
                                    </IconButton>
                                </TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {loading && (
                    <LinearProgress />
                )}
            </div>

            <RightDrawer title={form === "new" ? "Pajisje e re" : "Ndrysho pajisjen"}>
                <AddDevice onFinish={fetchData} />
            </RightDrawer>
        </>
    );
};

export default Devices;