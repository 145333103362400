import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Table, TableHead, TableRow, TableCell, TableBody, LinearProgress, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { getExpenses, getDevices, toggleDrawer } from '../../redux/actions/app';
import RightDrawer from '../../components/right-drawer/RightDrawer';
import AddExpense from './components/AddExpense';
import MainPage from '../../components/main-page/MainPage';
import toast from 'react-hot-toast';
import moment from 'moment';

const Expenses = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const expenses = useSelector((state) => state.app.expenses);
    const devices = useSelector((state) => state.app.devices);

    const [filters, setFilters] = useState({ device_id: "all", });

    const fetchData = useCallback(() => {
        dispatch(getDevices()).then(res => {
            dispatch(getExpenses(filters)).then(res => {
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
            })
        }).catch(err => {
            setLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        })
    }, [dispatch, filters]);

    useEffect(() => {
        fetchData();
    }, [fetchData, filters]);

    const onAddNewExpense = () => {
        dispatch(toggleDrawer(true));
    }

    const total = (items) => {
        let total = 0;
        items.forEach(element => {
            total = parseFloat(total) + parseFloat(element.price);
        });
        return total;
    }

    return (
        <>
            <MainPage title={`Lista e shpenzimeve ${expenses.length}`} buttonTitle="Shpenzim i ri" onButtonClick={onAddNewExpense} />

            <div>
                <FormControl fullWidth size='small'>
                    <InputLabel id="demo-simple-select-label">Filtro sipas pajisjes</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filters.device_id}
                        label="Selekto pajisjen"
                        name="device_id"
                        onChange={(e) => setFilters({ ...filters, device_id: e.target.value})}
                    >
                        <MenuItem key="a" value="all">Te gjitha</MenuItem>
                        {devices.length > 0 && devices.map((device) => (
                            <MenuItem key={device.id} value={device.id}>{device.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            {!loading && expenses && (
                <div className='mt-3 ms-1'>Totali: <b>{total(expenses)} €</b></div>
            )}

            <div className='mt-2' style={{ overflow: 'auto' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Pagesa</b></TableCell>
                            <TableCell><b>Arsyeja</b></TableCell>
                            <TableCell><b>Info</b></TableCell>
                            <TableCell><b>Data</b></TableCell>
                            <TableCell><b>Pajisja</b></TableCell>
                            <TableCell><b>Emri klientit</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading && expenses && expenses.map((expense) => (
                            <TableRow key={expense.id} >
                                <TableCell>{expense.price} €</TableCell>
                                <TableCell>{expense.reason}</TableCell>
                                <TableCell>{expense.info}</TableCell>
                                <TableCell>{moment(expense.created_at).format('L')}</TableCell>
                                <TableCell>{expense.device.name}</TableCell>
                                <TableCell>{expense.reservation ? expense.reservation.contact_person : ""}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {loading && (
                    <LinearProgress />
                )}

                <RightDrawer title="Shpenzim i ri">
                    {devices && (
                        <AddExpense devices={devices} onFinish={fetchData} />
                    )}
                </RightDrawer>
            </div>
        </>
    );
};

export default Expenses;