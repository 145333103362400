import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawer, getDevices, getReservations, getPaymentHistoryOfReservation, getExpensesOfReservation } from '../../redux/actions/app';
import { MenuItem, FormControl, InputLabel, Select, LinearProgress, Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from "./home.module.scss";
import toast from 'react-hot-toast';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

const Home = () => {
    const dispatch = useDispatch();
    const devices = useSelector((state) => state.app.devices);
    const reservations = useSelector((state) => state.app.reservations);

    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [filters, setFilters] = useState({ status: "active", device_id: "all", contact_person: "" });
    const [event, setEvent] = useState();
    const [modalEvent, setModalEvent] = useState(false);

    const [paymentsHistory, setPaymentsHistory] = useState([]);
    const [expensesHistory, setExpensesHistory] = useState([]);

    const fetchData = useCallback(() => {
        dispatch(getDevices()).then(res => {
            dispatch(getReservations(filters)).then(res => {
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
            })
        }).catch(err => {
            setLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        });
    }, [dispatch, filters]);

    useEffect(() => {
        fetchData();
    }, [fetchData, filters]);

    useEffect(() => {
        const ev = reservations;
        ev.forEach(element => {
            return [
                element.title = element.contact_person,
                element.start = element.date,
                element.end = element.date,
                element.eventClasses = 'optionalEvent',
            ];
        });
        setEvents(ev);
    }, [reservations]);

    const onEventClick = (event) => {
        setEvent(event);
        setModalEvent(true);
    };

    useEffect(() => {
        if(event){
            dispatch(getPaymentHistoryOfReservation(event.id)).then(res => {
                setPaymentsHistory(res.data);
            });
            dispatch(getExpensesOfReservation(event.id)).then(res => {
                setExpensesHistory(res.data);
            });
        }
    }, [dispatch, event]);

    return (
        <div>
            {loading ? (
                <div>
                    <LinearProgress />
                </div>   
            ) : (
                <>
                    <div className='mb-2'>
                        <FormControl fullWidth size='small'>
                            <InputLabel id="demo-simple-select-label">Filtro sipas pajisjes</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={filters.device_id}
                                label="Selekto pajisjen"
                                name="device_id"
                                onChange={(e) => setFilters({ ...filters, device_id: e.target.value})}
                            >
                                <MenuItem key="a" value="all">Te gjitha</MenuItem>
                                {devices.length > 0 && devices.map((device) => (
                                    <MenuItem key={device.id} value={device.id}>{device.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 460 }}
                        views={['month']}
                        eventPropGetter={(event, start, end, isSelected) => {
                            let newStyle = {
                                backgroundColor: event.device.color,
                                color: 'white',
                                borderRadius: "5px",
                            };
                            return {
                                className: "",
                                style: newStyle
                            };
                        }}
                        onSelectEvent={(event) => onEventClick(event)}
                    />
                </>
            )}

        {event && modalEvent && (
            <Dialog fullWidth onClose={() => setModalEvent(false)} open={modalEvent}>
                <>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <IconButton onClick={() => setModalEvent(false)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className='history-card'  style={{ marginLeft: '20px', marginRight: '20px', marginBottom: '20px', border: 'none' }}>
                        <p>Klienti: <span>{event.contact_person}</span></p>
                        <p>Kontakti: <span>{event.contact_person_mobile}</span></p>
                        <p>Adresa: <span>{event.address}</span></p>
                        <p>Eventi: <span>{event.event}</span></p>
                        <p>Info: <span>{event.info}</span></p>
                        <p>Data: <span>{moment(event.date).format('L')}</span></p>
                        <p>Ora: <span>{event.time_from}</span></p>
                        <p>Kohezgjatja: <span>{event.duration}</span></p>
                        <p>Cmimi: <span>{event.price} €</span></p>
                        <p>Cmimi i paguar: <span>{event.payed_price} €</span></p>
                    </div>
                    {paymentsHistory.length > 0 && (
                        <div className='mt-1'>
                            <p style={{ fontSize: '14px', textAlign: 'center' }}><b>Historia e pagesave</b></p>
                            {paymentsHistory.map(his => (
                                <div className='history-card' key={his.id} style={{ position: 'relative' }}>
                                    <p>Cmimi: <span>{his.price} €</span></p>
                                    <p>Lloji: <span>{his.type === "kapare" ? "Kapare" : his.type === "tips" ? "Bakshish" : "Pagese"}</span></p>
                                    {his.info && (<p>Info: <span>{his.info}</span></p>)}
                                    <p>Data: <span>{his.created_at}</span></p>
                                </div>
                            ))}
                        </div>
                    )}
                    {expensesHistory.length > 0 && (
                        <div className='mt-3'>
                            <p style={{ fontSize: '14px', textAlign: 'center' }}><b>Historia e shpenzimeve</b></p>
                            {expensesHistory.map(his => (
                                <div className='history-card' key={his.id} style={{ position: 'relative' }}>
                                    <p>Cmimi: <span>{his.price} €</span></p>
                                    <p>Arsye: <span>{his.reason}</span></p>
                                    {his.info && (<p>Info: <span>{his.info}</span></p>)}
                                    <p>Data: <span>{his.created_at}</span></p>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            </Dialog>
        )}
        </div>
    );
};

export default Home;