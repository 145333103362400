import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Table, TableHead, TableRow, TableCell, TableBody, LinearProgress, IconButton, Menu, MenuItem, FormControl, InputLabel, Select, TextField } from '@mui/material';
import MainPage from '../../components/main-page/MainPage';
import { toggleDrawer, getDevices, getReservations, cancelReservation, activateReservation } from '../../redux/actions/app';
import RightDrawer from '../../components/right-drawer/RightDrawer';
import AddReservation from './components/AddReservation';
import toast from 'react-hot-toast';
import AddPayment from './components/AddPayment';
import AddExpense from './components/AddExpense';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditReservation from './components/EditReservation';
import moment from 'moment';

let debounce = null;

const Reservations = () => {
    const dispatch = useDispatch();
    const devices = useSelector((state) => state.app.devices);
    const reservations = useSelector((state) => state.app.reservations);

    const [loading, setLoading] = useState(true);
    const [form, setForm] = useState("");
    const [selectedReservation, setSelectedReservation] = useState();
    const [filters, setFilters] = useState({ status: "active", device_id: "all", contact_person: "" });
    const [name, setName] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClickMoreButton = (event, reservation) => {
        console.log("REs", reservation);
        setAnchorEl(event.currentTarget);
        setSelectedReservation(reservation);
    };
    const handleCloseMoreButton = () => {
        setAnchorEl(null);
    };
    
    const onAddNewReservation = () => {
        setForm("new-reservation")
        dispatch(toggleDrawer(true));
    }

    const onAddNewPayment = () => {
        setAnchorEl(null);
        setForm("new-payment");
        dispatch(toggleDrawer(true));
    }

    const onAddNewExpense = () => {
        setAnchorEl(null);
        setForm("new-expense");
        dispatch(toggleDrawer(true));
    }

    const onUpdateReservation = () => {
        setAnchorEl(null);
        setForm("update");
        dispatch(toggleDrawer(true));
    }

    const onCancelReservation = () => {
        setAnchorEl(null);
        dispatch(cancelReservation(selectedReservation.id)).then(res => {
            toast.success('Rezervimi u anulua!');
            fetchData();
        }).catch(err => {
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        })
    }

    const onActiveReservation = () => {
        setAnchorEl(null);
        dispatch(activateReservation(selectedReservation.id)).then(res => {
            toast.success('Rezervimi u aktivizua!');
            fetchData();
        }).catch(err => {
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        })
    }

    const fetchData = useCallback(() => {
        dispatch(getDevices()).then(res => {
            dispatch(getReservations(filters)).then(res => {
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
            })
        }).catch(err => {
            setLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        });
    }, [dispatch, filters]);

    useEffect(() => {
        fetchData();
    }, [fetchData, filters]);

    // Search
    const searchByName = (e) => {
        setName(e.target.value)
        clearTimeout(debounce);
        debounce = setTimeout(() => {
            setFilters({ ...filters, contact_person: e.target.value })
        }, 500);
    }

    return (
        <>
            <MainPage title={`Lista e rezervimeve ${reservations.length}`} buttonTitle="Rezervim i ri" onButtonClick={onAddNewReservation} />

            <div>
                <TextField 
                    id="outlined-basic" 
                    size="small" 
                    variant="outlined" 
                    onChange={searchByName} 
                    className="my-2" 
                    value={name} 
                    label="Filtro sipas klientit" 
                    fullWidth={true}
                />
                <FormControl fullWidth size='small' className="my-2">
                    <InputLabel id="demo-simple-select-label">Filtro sipas pajisjes</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filters.device_id}
                        label="Filtro sipas pajisjes"
                        name="device_id"
                        onChange={(e) => setFilters({ ...filters, device_id: e.target.value})}
                    >
                        <MenuItem key="a" value="all">Te gjitha</MenuItem>
                        {devices.length > 0 && devices.map((device) => (
                            <MenuItem key={device.id} value={device.id}>{device.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth size='small' className="my-2">
                    <InputLabel id="demo-simple-select-label">Filtro sipas statusit</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filters.status}
                        label="Filtro sipas statusit"
                        name="status"
                        onChange={(e) => setFilters({ ...filters, status: e.target.value})}
                    >
                        <MenuItem key="active" value="active">Aktive</MenuItem>
                        <MenuItem key="canceled" value="canceled">Anuluar</MenuItem>
                    </Select>
                </FormControl>
            </div>

            <div className='mt-2' style={{ overflow: 'auto' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Klienti</b></TableCell>
                            <TableCell><b>Data</b></TableCell>
                            <TableCell><b>Adresa</b></TableCell>
                            <TableCell><b>Pajisja</b></TableCell>
                            <TableCell><b>Totali</b></TableCell>
                            <TableCell><b>Cmimi paguar</b></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {!loading && reservations && reservations.map((reservation) => (
                        <TableRow key={reservation.id}>
                            <TableCell>
                                <div className='table-p-no-space'>
                                    <p>{reservation.contact_person}</p>
                                    <p>{reservation.contact_person_mobile}</p>
                                </div>
                            </TableCell>
                            <TableCell>{moment(reservation.date).format('L')}</TableCell>
                            <TableCell>{reservation.address}</TableCell>
                            <TableCell>{reservation.device.name}</TableCell>
                            <TableCell>{reservation.price} €</TableCell>
                            <TableCell>{reservation.payed_price} €</TableCell>
                            <TableCell>
                                <IconButton onClick={(e) => handleClickMoreButton(e, reservation)}>
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleCloseMoreButton}
                                    className="menu-more"
                                >
                                    <MenuItem onClick={() => onAddNewPayment()}>Pageset</MenuItem>
                                    <MenuItem onClick={() => onAddNewExpense()}>Shpenzimet</MenuItem>
                                    <MenuItem onClick={() => onUpdateReservation()}>Ndrysho</MenuItem>
                                    {selectedReservation && selectedReservation.status === 'active' ? (
                                        <MenuItem onClick={() => onCancelReservation()}>Anulo Rezervimin</MenuItem>
                                    ) : (
                                        <MenuItem onClick={() => onActiveReservation()}>Aktivizo Rezervimin</MenuItem>
                                    )}
                                </Menu>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                {loading && (
                    <LinearProgress />
                )}
            </div>

            <RightDrawer title={form === "new-reservation" ? "Rezervim i ri" : form === "new-expense" ? "Shpenzim i ri" : form === "update" ? "Ndrysho rezervimin" : "Pagese e re"}>
                {devices && (
                    form === "new-reservation" ? (<AddReservation devices={devices} onFinish={fetchData} /> )
                    : form === "new-expense" ? <AddExpense reservation={selectedReservation} onFinish={fetchData} /> 
                    : form === "update" ? <EditReservation devices={devices} reservation={selectedReservation} onFinish={fetchData} />
                    : <AddPayment reservation={selectedReservation} onFinish={fetchData} />
                )}
            </RightDrawer>
        </>
    );
};

export default Reservations;