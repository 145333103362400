import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AuthRoute from './routes/AuthRoute';

import Login from './pages/login/Login';
import Home from './pages/home/Home';
import Devices from './pages/devices/Devices';
import Reservations from './pages/reservations/Reservations';
import Payments from './pages/payments/Payments';
import Expenses from './pages/expenses/Expenses';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Login />} />
        <Route path="/home" exact element={<AuthRoute><Home /></AuthRoute>} />
        <Route path="/devices" exact element={<AuthRoute><Devices /></AuthRoute>} />
        <Route path="/reservations" exact element={<AuthRoute><Reservations /></AuthRoute>} />
        <Route path="/payments" exact element={<AuthRoute><Payments /></AuthRoute>} />
        <Route path="/expenses" exact element={<AuthRoute><Expenses /></AuthRoute>} />
      </Routes>
    </Router>
  );
}

export default App;
