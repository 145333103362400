import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Dashboard from '../components/dashboard/Dashboard';

const AuthRoute = ({ children }) => {
    const user = useSelector((state) => state.auth.user);

    return user ? <Dashboard>{children}</Dashboard> : <Navigate to="/" />
};

export default AuthRoute;