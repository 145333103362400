import React, { useState } from "react";
import { Container, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../../redux/actions/auth';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EmergencyRecordingIcon from '@mui/icons-material/EmergencyRecording';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PaidIcon from '@mui/icons-material/Paid';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';

import './agency-dashboard.scss';

const Dashboard = ({children}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);

    const [open, setOpen] = useState(false)

    const toggle = () => {
        setOpen(!open)
    }

    const logOutHandler = () => {
        dispatch(logoutUser());
        navigate("/");
    }

    const onLinkClick = (link) => {
        navigate(`/${link}`);
        setOpen(!open)
    }
    
    return(
        <div className="agency-dashboard">
            <div className="d-flex justify-content-between align-items-center navigation">
                <div onClick={toggle}>
                    {open ? <ArrowBackIcon /> : <MenuIcon />}
                </div>
                <IconButton onClick={logOutHandler}>
                    <ExitToAppIcon />
                </IconButton>
            </div>
            <div className={open ? "agency-sidebar-active" : "agency-sidebar"}>

                <div className="logo_content">
                    <div className="logo">
                        PËR RRETH
                    </div>
                    <div onClick={toggle} className="toggle-icon">
                        {open && <ArrowBackIcon />}
                    </div>
                </div>

                {user && open && (
                    <ul className="nav_list">
                        <li>
                            <div className="linku" onClick={() => onLinkClick("home")}>
                                <div className="icon"><CalendarMonthIcon /></div>
                                <span className="link_name">Kalendari</span>
                            </div>
                            <p className="tooltip">Kalendari</p>
                        </li>
                        <li>
                            <div className="linku" onClick={() => onLinkClick("devices")}>
                                <div className="icon"><EmergencyRecordingIcon /></div>
                                <span className="link_name">Pajisjet</span>
                            </div>
                            <p className="tooltip">Pajisjet</p>
                        </li>
                        <li>
                            <div className="linku" onClick={() => onLinkClick("reservations")}>
                                <div className="icon"><SummarizeIcon /></div>
                                <span className="link_name">Rezervimet</span>
                            </div>
                            <p className="tooltip">Rezervimet</p>
                        </li>
                        <li>
                            <div className="linku" onClick={() => onLinkClick("payments")}>
                                <div className="icon"><PaidIcon /></div>
                                <span className="link_name">Pagesat</span>
                            </div>
                            <p className="tooltip">Pagesat</p>
                        </li>
                        <li>
                            <div className="linku" onClick={() => onLinkClick("expenses")}>
                                <div className="icon"><MoneyOffIcon /></div>
                                <span className="link_name">Shpenzimet</span>
                            </div>
                            <p className="tooltip">Shpenzimet</p>
                        </li>
                    </ul>
                )}

                
            </div>
            <div className={open ? "agency-container-active" : ""}>
                {open ? (
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} onClick={toggle}>
                    </Container>
                ) : (
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        {children}
                    </Container>
                )}
            </div>
        </div>
    )
}

export default Dashboard;