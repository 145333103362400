import * as c from "../constants";

const initialState = () => {
    return {
        isRightDrawerOpen: false,
        loading: false,
        devices: [],
        reservations: [],
        payments: [],
        expenses: []
    }
}

export const appReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.TOGGLE_DRAWER:
            return { ...state, isRightDrawerOpen: action.payload }
        case c.TOGGLE_LOADING_FORM:
            return { ...state, loading: action.payload }
        case c.GET_DEVICES:
            return { ...state, devices: action.payload }
        case c.GET_RESERVATIONS: 
            return { ...state, reservations: action.payload }
        case c.GET_PAYMENTS:
            return { ...state, payments: action.payload }
        case c.GET_EXPENSES:
            return { ...state, expenses: action.payload }
        default:
            return state
    }
}
