import React from 'react';
import { Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import styles from './MainPage.module.scss';

const MainPage = (props) => {
    const { title, buttonTitle, onButtonClick } = props;

    return (
        <>
            <div className={styles.MainPageWrapper}>
                <div className={styles.MainPageTitle}>
                    {title}
                </div>
                {onButtonClick && (
                    <Button onClick={onButtonClick} variant="contained" endIcon={<AddCircleOutlineIcon />}>
                        {buttonTitle}
                    </Button>
                )}
            </div>
            <hr></hr>
        </>
    );
};

export default MainPage;