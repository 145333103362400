import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, TextField, LinearProgress, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import { toggleLoading, toggleDrawer, addExpenseToReservation } from '../../../redux/actions/app';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';

const AddExpense = (props) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const validationSchema = yup.object({
        device_id: yup.string().required('Ju lutem selektoni pajisjen'),
        price: yup.string("").required('Ju lutem shkruani shumën për pagesë'),
        reason: yup.string().required("Ju lutem shkruani arsyen e ketij shpenzimi"),
        info: yup.string()
    });

    const formik = useFormik({
        initialValues: {
          price: '',
          reason: "",
          info: "",
          device_id: "",
          reservation_id: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true);
            dispatch(toggleLoading(true));
            dispatch(addExpenseToReservation(values)).then(res => {
                setLoading(false);
                dispatch(toggleLoading(false));
                dispatch(toggleDrawer(false));
                toast.success('Shpenzimi u ruajt me sukses!');
                props.onFinish();
            }).catch(e => {
                setLoading(false);
                dispatch(toggleLoading(false));
                toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
            });
        },
    });

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className='mt-3'>
                    <FormControl fullWidth size='small' error={formik.errors.device_id}>
                        <InputLabel id="demo-simple-select-label">Selekto pajisjen</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formik.values.device_id}
                            label="Selekto pajisjen"
                            name="device_id"
                            onChange={formik.handleChange}
                        >
                            {props.devices.length > 0 && props.devices.map((device) => (
                                <MenuItem key={device.id} value={device.id}>{device.name}</MenuItem>
                            ))}
                        </Select>
                        {formik.errors.device_id && (
                            <FormHelperText style={{ color: 'red' }}>{formik.errors.device_id}</FormHelperText>
                        )}
                    </FormControl>
                </div> 
                <div className='mt-3'>
                    <TextField
                        autoFocus
                        name="price"
                        variant="outlined"
                        fullWidth
                        label="Pagesa"
                        type="number"
                        size="small"
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        error={formik.touched.price && Boolean(formik.errors.price)}
                        helperText={formik.touched.price && formik.errors.price}
                    />
                </div> 
                <div className='mt-3'>
                    <TextField
                        name="reason"
                        variant="outlined"
                        fullWidth
                        label="Arsyeja e shpenzimit"
                        size="small"
                        value={formik.values.reason}
                        onChange={formik.handleChange}
                        error={formik.touched.reason && Boolean(formik.errors.reason)}
                        helperText={formik.touched.reason && formik.errors.reason}
                    />
                </div>  
                <div className='mt-3'>
                    <TextField
                        name="info"
                        variant="outlined"
                        fullWidth
                        label="Info"
                        size="small"
                        value={formik.values.info}
                        onChange={formik.handleChange}
                        error={formik.touched.info && Boolean(formik.errors.info)}
                        helperText={formik.touched.info && formik.errors.info}
                    />
                </div>  
                <div className="text-center mt-3">
                    <Button variant="contained" type="submit">Ruaj</Button>
                </div>
                {loading && (
                    <div className='mt-3'>
                        <LinearProgress />
                    </div>
                )}
            </form>
        </div>
    );
};

export default AddExpense;