import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, TextField, LinearProgress, FormControl, InputLabel, MenuItem, Select, FormHelperText, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { toggleLoading, toggleDrawer, addReservationPrice, getPaymentHistoryOfReservation, deletePayment } from '../../../redux/actions/app';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';

const AddPayment = (props) => {
    const dispatch = useDispatch();
    const { reservation } = props;

    const [dept, setDept] = useState(reservation.price - reservation.payed_price);
    const [loading, setLoading] = useState(false);
    const [history, setHistory] = useState([]);

    const validationSchema = yup.object({
        price: yup.string("").required('Ju lutem shkruani shumën për pagesë'),
        type: yup.string().required("Ju lutem selektoni llojin e pageses"),
        info: yup.string(),
    });

    const formik = useFormik({
        initialValues: {
          price: '',
          type: "",
          info: "",
          device_id: reservation.device.id,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true);
            dispatch(toggleLoading(true));
            dispatch(addReservationPrice(values, reservation.id)).then(res => {
                setLoading(false);
                dispatch(toggleLoading(false));
                dispatch(toggleDrawer(false));
                toast.success('Pagesa u ruajt me sukses!');
                props.onFinish();
            }).catch(e => {
                setLoading(false);
                dispatch(toggleLoading(false));
                toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
            });
        },
    });

    const onDeletePayment = (id) => {
        dispatch(toggleLoading(true));
        dispatch(deletePayment(id)).then(res => {
            props.onFinish();
            dispatch(toggleLoading(false));
            dispatch(toggleDrawer(false));
            toast.success('Pagesa u fshi me sukses!');
        }).catch(err => {
            setLoading(false);
            dispatch(toggleLoading(false));
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        });
    }

    useEffect(() => {
        dispatch(getPaymentHistoryOfReservation(reservation.id)).then(res => {
            setHistory(res.data);
        });
    }, [dispatch, reservation]);

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className="mt-3">
                    <TextField
                        disabled
                        size="small"
                        variant="outlined"
                        fullWidth
                        label="Çmimi total"
                        name="totalPrice"
                        value={reservation.price}
                    />
                </div>
                <div className="mt-3">
                    <TextField
                        disabled
                        size="small"
                        variant="outlined"
                        fullWidth
                        label="Pagesa e kryer"
                        name="payedPrice"
                        value={reservation.payed_price}
                    />
                </div>
                {reservation.price > reservation.payed_price ? (
                    <div>
                        <p className="infoTextInAddPayment">Kanë mbetur edhe {dept.toFixed(2)} € për të kryer pagesën totale</p>
                    </div>
                ) : (
                    <p className="infoTextInAddPayment">Pagesa është e kryer!</p>
                )}
                <div className='mt-3'>
                    <TextField
                        autoFocus
                        name="price"
                        variant="outlined"
                        fullWidth
                        label="Pagesa"
                        type="number"
                        size="small"
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        error={formik.touched.price && Boolean(formik.errors.price)}
                        helperText={formik.touched.price && formik.errors.price}
                    />
                </div> 
                <div className='mt-3'>
                    <FormControl fullWidth size='small' error={formik.errors.type}>
                        <InputLabel id="demo-simple-select-label">Lloji</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formik.values.type}
                            label="Lloji"
                            name="type"
                            onChange={formik.handleChange}
                        >
                            <MenuItem value={"kapare"}>Kapare</MenuItem>
                            <MenuItem value={"payment"}>Pagese</MenuItem>
                            {/* <MenuItem value={"tips"}>Bakshish</MenuItem> */}
                        </Select>
                        {formik.errors.type && (
                            <FormHelperText style={{ color: 'red' }}>{formik.errors.type}</FormHelperText>
                        )}
                    </FormControl>
                </div> 
                <div className='mt-3'>
                    <TextField
                        name="info"
                        variant="outlined"
                        fullWidth
                        label="Info"
                        size="small"
                        value={formik.values.info}
                        onChange={formik.handleChange}
                        error={formik.touched.info && Boolean(formik.errors.info)}
                        helperText={formik.touched.info && formik.errors.info}
                    />
                </div>  
                <div className="text-center mt-3">
                    <Button variant="contained" type="submit">Ruaj pagesen</Button>
                </div>
                {loading && (
                    <div className='mt-3'>
                        <LinearProgress />
                    </div>
                )}
            </form>
            {history.length > 0 && (
                <div className='mt-3'>
                    <hr></hr>
                    <p style={{ fontSize: '14px', textAlign: 'center' }}><b>Historia e pagesave</b></p>
                    {history.map(his => (
                        <div className='history-card' key={his.id} style={{ position: 'relative' }}>
                            <IconButton style={{ position: 'absolute', right: '5px' }} size='small' onClick={() => onDeletePayment(his.id)}>
                                <DeleteIcon fontSize='small' />
                            </IconButton>
                            <p>Cmimi: <span>{his.price} €</span></p>
                            <p>Lloji: <span>{his.type === "kapare" ? "Kapare" : his.type === "tips" ? "Bakshish" : "Pagese"}</span></p>
                            {his.info && (<p>Info: <span>{his.info}</span></p>)}
                            <p>Data: <span>{his.created_at}</span></p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default AddPayment;